// Corrige un problème avec la modal de confirmation de mise en ligne en gestion de contenu
.jconfirm {
    .row {
        display: revert;
        margin: 0;
    }
}

// Corrige un problème avec le fil d'ariane sur certaines pages
.fil-ariane .first {
    display: inline;
}
#fil_ariane {
    strong {
        font-weight: normal;
    }
    a {
        &:not([href="#"]) {
            color: #{$link-color};
            text-decoration: underline;
        }
        &[href="#"] {
            pointer-events: none;
        }
        @media #{$large-and-up} {
            &:hover {
                text-decoration: none;
            }
        }
    }
}