.masonry-grid {  
  @media #{$medium-and-up} {
    display: grid;
    grid-gap: $gutter-width;    
    grid-auto-rows: 1rem;    
    &.m6-masonry {
      grid-template-columns: repeat(auto-fill, minmax(calc(50% - #{$gutter-width}),1fr));      
    }
    &.m4-masonry {
      grid-template-columns: repeat(auto-fill, minmax(calc(33.333% - (#{$gutter-width} * 2)),1fr));
    }
  }
  @media #{$large-and-up} {        
    &.l4-masonry {
      grid-template-columns: repeat(auto-fill, minmax(calc(33.333% - (#{$gutter-width} * 2)),1fr));
    }
    &.l3-masonry {
      grid-template-columns: repeat(auto-fill, minmax(calc(25% - (#{$gutter-width} * 2)),1fr));
    }
  }
}