:where(.white) {
    background-color: white;
}
:where(.white-text),
.white-text a {
    color: white;
}

@mixin colorClass($value) {
    .#{$value} { background-color: var(--#{$value}-color); }
    .#{$value}-text { color: var(--#{$value}-color); }
    $i: 1;
    @while $i <= 9 {
        .#{$value}-#{$i}0 {
            background-color: var(--#{$value}-color-#{$i}0);
        }
        .#{$value}-text-#{$i}0 {
           color: var(--#{$value}-color-#{$i}0);
        }
        $i: $i + 1;
    }
}
@include colorClass(primary);
@include colorClass(secondary);
@include colorClass(accent);
@include colorClass(background);
.text-color {
    color: var(--text-color);
}
$i: 1;
@while $i <= 9 {
    .text-color-#{$i}0 {
       color: var(--text-color-#{$i}0);
    }
    $i: $i + 1;
}