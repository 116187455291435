form {
    @at-root :where(& ul) {
        padding-left: 0;
        list-style: none;
    }
    // fieldset {
    //     legend {}
    // }
}

label {
    display: block;
    padding-bottom: .5rem;
    @at-root .generateur &.required,
    .divGenerateurRequired {
        .required {
            color: $error-color;
        }
    }
    .rgpd & {
        &.optional {
            display: none;
        }
        &.required {
            display: none;
            & + ul .noFloatingLabel {
                &::after {
                    content: '\00A0 \002A';
                    color: $error-color;
                }
            }
        }
    }
}

.divGenerateur,
.divGenerateurErreur {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

input:not([type]),
input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=time],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=tel],
input[type=number],
input[type=search],
textarea {
    width: 100%;
    height: auto;
    padding: {
        top: .5rem;
        top: calc(.5rem - 1px);
        right: 1rem;
        bottom: .5rem;
        bottom: calc(.5rem - 1px);
        left: 1rem;
    }
    font: {
        size: 1rem;
    }
    color: $text-color;
    line-height: 1.5rem;
    border: {
        style: solid;
        width: 1px;
        color: $text-color-80;
        radius: $button-radius;
    }
    background-color: transparent;
    outline: none;
    transition: {
        property: border-color, background-color;
        duration: .3s;
        timing-function: ease-in-out;
    }
    &::placeholder {
        color: $text-color-40;
    }
    .erreur & {
        color: $error-color;
        border-color: $error-color;
        &::placeholder {
            color: $error-color;
        }
    }
    &:focus {
        color: black;
        border-color: $text-color;
        background-color: white;
    }
    &[disabled],
    &.disabled {
        color: $text-color-60;
        background-color: $text-color-90;
        opacity: 1;
        pointer-events: none;
        user-select: none;
        &::placeholder {
            color: $text-color-60;
        }
    }
    @media #{$large-and-up} {
        &:hover {
            border-color: $text-color;
        }
    }
    .white-text & {
        color: white;
        border-color: hsla(0, 0%, 100%, .4);
        &::placeholder {
            color: hsla(0, 0%, 100%, .6);
        }
        &:focus {
            color: $text-color;
            border-color: white;
            &::placeholder {
                color: $text-color-40;
            }
        }
        &[disabled],
        &.disabled {
            color: hsla(0, 0%, 100%, .4);
            background-color: hsla(0, 0%, 100%, .1);
            &::placeholder {
                color: hsla(0, 0%, 100%, .4);
            }
        }
        @media #{$large-and-up} {
            &:hover {
                border-color: white;
            }
        }
    }
    .white-text .erreur & {
        color: $error-color;
        border-color: $error-color;
        &::placeholder {
            color: $error-color;
        }
        &:focus {
            color: $text-color;
            border-color: white;
            &::placeholder {
                color: $text-color-40;
            }
        }
        @media #{$large-and-up} {
            &:hover {
                border-color: white;
            }
        }
    }
}

select {
    color: black;
    background-color: white;
}

.select-wrap {
    display: block;
    position: relative;
    select {
        // -webkit-appearance: none;
        // appearance: none;
        display: block;
        width: 100%;
        height: auto;
        padding: {
            top: .5rem;
            top: calc(.5rem - 1px);
            right: 2rem;
            bottom: .5rem;
            bottom: calc(.5rem - 1px);
            left: 1rem;
        }
        font-size: 1rem;
        line-height: 1.5rem;
        color: black;
        border: {
            style: solid;
            width: 1px;
            color: $text-color-80;
            radius: $button-radius;
        }
        outline: none;
        background-color: white;
        text-overflow: ellipsis;
        transition: {
            property: border-color;
            duration: .3s;
            timing-function: ease-in-out;
        }
        &::-ms-expand {
            display: none;
        }
        .erreur & {
            color: $error-color;
            border-color: $error-color;
        }
        &:focus {
            border-color: $text-color;
        }
        &[disabled],
        &.disabled {
            color: $text-color-60;
            background-color: $text-color-90;
            opacity: 1;
            pointer-events: none;
            user-select: none;
        }
        // option {}
        // optgroup {
        //     option {}
        // }
        // &[multiple] {
        //     option {}
        //     optgroup {
        //         option {}
        //     }
        // }
    }
    &::after {
        display: block;
        margin-right: .5rem;
        content: '\e5d7 ';
        font: {
            family: #{$icon-font};
            size: 1.5rem;
        }
        color: $text-color-60;
        line-height: 1;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        transition: {
            property: color;
            duration: .3s;
            timing-function: ease-in-out;
        }
        pointer-events: none;
    }
    @media #{$large-and-up} {
        select {
            &:hover {
                border-color: $text-color;
            }
            @at-root label:hover + &[disabled],
            label:hover + &.disabled {
                border-color: $text-color-80;
            }
            .erreur label:hover + &[disabled] {
                border-color: $error-color;
            }
        }
    }
}

input[type=checkbox],
input[type=radio] {
    margin-right: 1rem;
}

// input[type=button],
// input[type=reset],
// input[type=submit] {}
// input[type=color] {}
// input[type=file] {}
// input[type=hidden] {}
// input[type=image] {}
// input[type=range] {}

.erreur, 
.divGenerateurErreur {
    .erreur {
        display: block;
        padding-bottom: .5rem;
        color: $error-color;
        float: right;
    }
    label:not(.noFloatingLabel) {
        float: left;
    }
    input, select, textarea, .noFloatingLabel {
        clear: both;
    }
}

.validation {
    display: none;
}