// TOP BAR MOBILE
.menu-mobile {
  height: 3.5rem;
  z-index: 10;
  background-color: #{$primary-color-darken};   
  transition: all .3s;
  .container,
  .menu-mobile-content { 
    height: 100%;    
    flex-wrap: nowrap;
  }
  .menu-title {
    display: none;
    text-transform: uppercase;
    letter-spacing: .125rem;
  }
  #menu-back {
    display: none;
  }
  .btn-menu {
    height: 3rem;
    color: #fff;
    border: none;
    background: none;       
    transition: color .3s;
    .close-mobile {
      display: none;
    }
  }
  @media #{$large-and-up} {
    display: none;
  }
}

// MENU MOBILE OUVERT
.menu_opened {  
  .menu-title,
  #menu-burger .close-mobile {
    display: block; 
  }
  .topbar-brand, 
  #menu-burger span:not(.close-mobile) {
    display: none;
  }
  &.MenuLevel2Opened {
    .menu-mobile #menu-back {
      display: block;
    }
    .menu-title {
      display: none;
    }
  }
  &.MenuLevel3Opened {
    .header-content .nav > ul > .sous_menu.opened > a {
      display: none;
    }
  }
} 

// NAVIGATION 
.header-content {
  #nav-container {
    position: fixed;
    top: 0;
    right: -100%;
    left: auto;
    width: 100%;
    height: 100vh;
    margin-top: 3.5rem;
    padding-top: 0;
    padding-bottom: 3.5rem;
    z-index: 100;
    background-color: #{$background-color};
    overflow-y: auto;
    transition: right .3s;
  }
  .nav {      
    ul {
      margin-top: 0;
      margin-bottom: 0;
      li {
        a {
          display: block;
          &.selected {
            color: #{$primary-color};
            background-color: #{$primary-color-90};
          }
        } 
        &.no-menu { display: none; }
        &.no-link > a {
          opacity: .7;
          &:hover,
          &.selected {
            color: #{$text-color};
            background-color: transparent;
            cursor: default;
            opacity: .7;
          }
        }
      }
    }
    @media #{$medium-and-down} {
      &#main-nav {
        background-color: #{$background-color};
        li:not(.opened) a {
          padding: .75rem 5%;
          text-decoration: none;
          border-bottom: solid 1px #ebebeb;
          &.selected {
            border-bottom-color: #{$primary-color};
          }
        }
      }
      &#topbar-nav li:not(.opened) {
        a {
          padding: .5rem 5%;
        }
        &:first-child a {
          padding-top: .75rem;
        }
      }
    }
  }
  // SOUS-MENUS NIVEAUX 2 ET 3
  @media #{$medium-and-down} {
    .nav {
      .sous_menu {
        position: relative;
        .btnMenuLevel2,
        .btnMenuLevel3 {
          position: absolute;
          right: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3rem;
          height: 100%;
          color: #{$primary-color};
          border-left: solid 1px #ebebeb;
          z-index: 1;
          &::after {
            content: '\e5cc';
            font-family: #{$icon-font};
            font-size: 1.5rem;
          }
        }
        > a {          
          padding-right: 4rem;
        }        
      }
      &#topbar-nav .sous_menu .btnMenuLevel2,
      &#topbar-nav .sous_menu .btnMenuLevel3 {
        color: #{$text-color}; 
        border-left: none;
      }
      .dropdown,
      .ssdropdown {
        position: fixed;
        top: 3.5rem;
        bottom: 0;
        left: auto;
        right: -100%;
        width: 100%;
        height: 100vh;
        background-color: #{$background-color};
        z-index: 9;
        overflow-y: auto;
        transition: right .3s;
      } 
      > ul > .sous_menu,
      .dropdown > .sous_menu {
        &.opened > a {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(100% - 8rem);
          height: 3.5rem;
          margin: auto; 
          padding: 0;
          line-height: 1.25;
          color: #fff;
          border-bottom: none;
        }  
      }
      > ul > .sous_menu.opened ul.dropdown,
      .dropdown > .sous_menu.opened ul.ssdropdown {
        right: 0;
      }    
    } 
  }
  // NAVIGATION DESKTOP   
  @media #{$large-and-up} { 
    background: #{$background-color};
    z-index: 100;
    transition: all .3s;
    #nav-container {
      position: static;
      right: 0;
      height: 100%;
      margin: .5rem 0;
      padding: 0;
      background-color: transparent;
      overflow: unset;
      transition: none;
    }
    .nav {
      &#main-nav {
        > ul  > li > a {
          padding: {
            top: 1rem;
            right: $gutter-width / 2;
            bottom: 1rem;
            left: $gutter-width / 2; 
          }
        }
      }    
      &#topbar-nav {
        > ul > li > a,
        .dropdown li a {
          padding: .5rem .75rem;
        }
        .ssdropdown li a {
          padding: .25rem .75rem;
          margin: 0;
        }
      }
      ul {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        width: auto;
        li {
          a {
            line-height: 1rem; 
            border-bottom: none;
            &.selected {
              background-color: transparent;
            }
          }
          &:hover > a {
            color: #{$primary-color};
          }
          &.sous_menu {
            position: relative;
            .btnMenuLevel2,
            .btnMenuLevel3 {
              display: none;
            }
            a::after {
              position: absolute;
              right: .625rem;
              display: block;         
              font-family: #{$icon-font};
              line-height: 0; 
            }
            > a::after {
              content: '\e5cf';
              position: static;
              display: inline-block;
              vertical-align: middle;
              margin: -.125em 0 0 .25em;
            }          
            ul.dropdown {
              position: absolute;
              left: 0;
              display: none;
              flex-direction: column;
              align-items: stretch;
              width: max-content;
              min-width: 100%;
              max-width: 20rem;
              margin: 0;
              padding: 0;
              font-weight: normal;
              background-color: #{$background-color};
              border: solid 1px rgba(0, 0, 0, .1);
              z-index: 1;
              > li {
                a {
                  padding: .75rem;
                  margin: 0;
                }
                &:not(:last-child) {
                  border-bottom: 1px solid #e0e0e0;
                }
                &:last-child > a {
                  border-bottom: none;
                }
                &.sous_menu {
                  position: relative;
                  &.empty-link > a {
                    border-right: none;
                    cursor: default;
                  }
                  &:not(.empty-link):hover > a {
                    color: #{$primary-color};
                  }
                  > a {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: .75rem;
                    margin-right: 2rem;
                    border-right: 1px solid #e0e0e0;
                    &:last-child {
                      border-bottom: none;
                    }
                  }
                  > a::after {
                    content: '\e5cc';
                    position: absolute;
                    margin-top: 0;
                    pointer-events: none;
                  }
                  &:hover ul.ssdropdown {
                    display: block;
                  }
                }
              }
            }
            ul.ssdropdown {
              position: absolute;
              top: -1px;
              left: 100%; 
              display: none;
              width: max-content;
              max-width: 20rem;
              padding-top: .25rem;
              padding-bottom: .25rem;
              background-color: #{$background-color};
              border: solid 1px rgba(0,0,0,.1);
              li,
              li:not(:last-child) {
                border-bottom: none;
                a {
                  padding: .5rem 0;
                  margin: 0 1rem;
                  color: #{$text-color};
                  border-bottom: none;
                  border-right: none;
                  &:hover {
                    color: #{$primary-color};
                  }
                }
              }
            }
            &:hover {
              ul.dropdown {
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}

// TAP BAR MOBILE
@keyframes slidein {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

.tap-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  z-index: 99;
  background: linear-gradient(hsla(#{$background-h}, #{$background-s}, #{$background-l}, 0), #{$background-color});
  transform: translateY(100%);
  animation: slidein .75s ease-out 1s;
  animation-fill-mode: forwards;
  .col {
    &:not(:first-child) {
      padding-left: .25rem;
    }
    &:not(:last-child) {
      padding-right: .25rem;
    }
  }  
  .tap-bar-item {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: .25rem;
    span {
      margin: .5rem 0;
      font-size: 2rem;
    }
    a {
      text-decoration: none;
     &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .contact-page & {
    .tap-bar-contact a,
    .tap-bar-contact span {
      pointer-events: none;
      opacity: .5;
    }
  } 
  .devis-page & {
    .tap-bar-devis a,
    .tap-bar-devis span {
      pointer-events: none;
      opacity: .5;
    }
  }
} 

// STICKY HEADER
.sticky-element {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all .3s;
  z-index: 100;
  .sticky-active & {
    &.sticky-scroll-down {
      transform: translateY(-100%);
      .menu_opened & {
        transform: translateY(0); 
      }
    }
    &.sticky-scroll-up {
      transform: translateY(0);
    }
  }
}

// NAV FOOTER

footer {
  nav a:hover {
    color: #{$primary-color};
  }
  .white-text nav a:hover {
    color: white;
    opacity: .75;
  } 
}