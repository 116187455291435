// Colors
// ==========================================================================

$primary-h: var(--primary-h) !default;
$primary-s: var(--primary-s) !default;
$primary-l: var(--primary-l) !default;

$primary-color: var(--primary-color) !default;
$primary-color-lighten: var(--primary-color-lighten) !default;
$primary-color-darken: var(--primary-color-darken) !default;
$primary-color-10: var(--primary-color-10) !default;
$primary-color-20: var(--primary-color-20) !default;
$primary-color-30: var(--primary-color-30) !default;
$primary-color-40: var(--primary-color-40) !default;
$primary-color-50: var(--primary-color-50) !default;
$primary-color-60: var(--primary-color-60) !default;
$primary-color-70: var(--primary-color-70) !default;
$primary-color-80: var(--primary-color-80) !default;
$primary-color-90: var(--primary-color-90) !default;

$secondary-h: var(--secondary-h) !default;
$secondary-s: var(--secondary-s) !default;
$secondary-l: var(--secondary-l) !default;

$secondary-color: var(--secondary-color) !default;
$secondary-color-lighten: var(--secondary-color-lighten) !default;
$secondary-color-darken: var(--secondary-color-darken) !default;
$secondary-color-10: var(--secondary-color-10) !default;
$secondary-color-20: var(--secondary-color-20) !default;
$secondary-color-30: var(--secondary-color-30) !default;
$secondary-color-40: var(--secondary-color-40) !default;
$secondary-color-50: var(--secondary-color-50) !default;
$secondary-color-60: var(--secondary-color-60) !default;
$secondary-color-70: var(--secondary-color-70) !default;
$secondary-color-80: var(--secondary-color-80) !default;
$secondary-color-90: var(--secondary-color-90) !default;

$accent-h: var(--accent-h) !default;
$accent-s: var(--accent-s) !default;
$accent-l: var(--accent-l) !default;

$accent-color: var(--accent-color) !default;
$accent-color-lighten: var(--accent-color-lighten) !default;
$accent-color-darken: var(--accent-color-darken) !default;
$accent-color-10: var(--accent-color-10) !default;
$accent-color-20: var(--accent-color-20) !default;
$accent-color-30: var(--accent-color-30) !default;
$accent-color-40: var(--accent-color-40) !default;
$accent-color-50: var(--accent-color-50) !default;
$accent-color-60: var(--accent-color-60) !default;
$accent-color-70: var(--accent-color-70) !default;
$accent-color-80: var(--accent-color-80) !default;
$accent-color-90: var(--accent-color-90) !default;

$text-h: var(--text-h) !default;
$text-s: var(--text-s) !default;
$text-l: var(--text-l) !default;

$text-color: var(--text-color) !default;
$text-color-10: var(--text-color-10) !default;
$text-color-20: var(--text-color-20) !default;
$text-color-30: var(--text-color-30) !default;
$text-color-40: var(--text-color-40) !default;
$text-color-50: var(--text-color-50) !default;
$text-color-60: var(--text-color-60) !default;
$text-color-70: var(--text-color-70) !default;
$text-color-80: var(--text-color-80) !default;
$text-color-90: var(--text-color-90) !default;

$background-h: var(--background-h) !default;
$background-s: var(--background-s) !default;
$background-l: var(--background-l) !default;

$background-color: var(--background-color) !default;
$background-color-10: var(--background-color-10) !default;
$background-color-20: var(--background-color-20) !default;
$background-color-30: var(--background-color-30) !default;
$background-color-40: var(--background-color-40) !default;
$background-color-50: var(--background-color-50) !default;
$background-color-60: var(--background-color-60) !default;
$background-color-70: var(--background-color-70) !default;
$background-color-80: var(--background-color-80) !default;
$background-color-90: var(--background-color-90) !default;

$link-color: var(--link-color) !default;

$success-color: var(--success-color) !default;
$error-color: var(--error-color) !default;
$info-color: var(--info-color) !default;


// Media Query Ranges
// ==========================================================================

$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$small-screen-up: $small-screen + 1 !default;
$medium-screen-up: $medium-screen + 1 !default;
$large-screen-up: $large-screen + 1 !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;


// Grid
// ==========================================================================

$container-max-width: 1200px !default;
$num-cols: 12 !default;
$gutter-width: 1.5rem !default;


// Typography
// ==========================================================================

$line-height: 1.5 !default;
$primary-font-stack: var(--primary-font) !default;
$secondary-font-stack: var(--secondary-font) !default;
$icon-font: var(--icon-font) !default;

$headings-line-height: var(--headings-line-height) !default;

$h1-font-size: var(--h1-font-size) !default;
$h2-font-size: var(--h2-font-size) !default;
$h3-font-size: var(--h3-font-size) !default;
$h4-font-size: var(--h4-font-size) !default;
$h5-font-size: var(--h5-font-size) !default;
$h6-font-size: var(--h6-font-size) !default;


// Buttons
// ==========================================================================

// $button-border: none !default;
$button-font-size: 16px !default;
// $button-icon-font-size: 1.25rem !default;
$button-radius: var(--button-border-radius) !default;


// Radius
// ==========================================================================

$border-radius: var(--border-radius) !default;


// Box-shadow
// ==========================================================================

$box-shadow-1: var(--box-shadow-1) !default;
$box-shadow-2: var(--box-shadow-2) !default;
$box-shadow-3: var(--box-shadow-3) !default;
$box-shadow-4: var(--box-shadow-4) !default;
$box-shadow-5: var(--box-shadow-5) !default;
$box-shadow-primary-1: var(--box-shadow-primary-1) !default;
$box-shadow-primary-2: var(--box-shadow-primary-2) !default;
$box-shadow-primary-3: var(--box-shadow-primary-3) !default;
$box-shadow-primary-4: var(--box-shadow-primary-4) !default;
$box-shadow-primary-5: var(--box-shadow-primary-5) !default;


// Slider
// ==========================================================================

$img-slide-01: var(--img-slide-01) !default;
$img-slide-02: var(--img-slide-02) !default;
$img-slide-03: var(--img-slide-03) !default;


/* Variables
 * ========================================================================== */

@mixin colorVar($value) {
    $i: 1;
    @if $value == 'secondary' {
        --#{$value}-color: hsla(var(--#{$value}-h, var(--primary-h)), var(--#{$value}-s, var(--primary-s)), var(--#{$value}-l, var(--primary-l)), 1);
        --#{$value}-color-lighten: hsla(var(--#{$value}-h, var(--primary-h)), var(--#{$value}-s, var(--primary-s)), calc(var(--#{$value}-l, var(--primary-l)) + 10%), 1);
        --#{$value}-color-darken: hsla(var(--#{$value}-h, var(--primary-h)), var(--#{$value}-s, var(--primary-s)), calc(var(--#{$value}-l, var(--primary-l)) - 10%), 1);
        @while $i <= 9 {
            --#{$value}-color-#{$i}0: hsla(var(--#{$value}-h, var(--primary-h)), var(--#{$value}-s, var(--primary-s)), #{$i}0%, 1);
            $i: $i + 1;
        }
    } @else {
        --#{$value}-color: hsla(var(--#{$value}-h), var(--#{$value}-s), var(--#{$value}-l), 1);
        --#{$value}-color-lighten: hsla(var(--#{$value}-h), var(--#{$value}-s), calc(var(--#{$value}-l) + 10%), 1);
        --#{$value}-color-darken: hsla(var(--#{$value}-h), var(--#{$value}-s), calc(var(--#{$value}-l) - 10%), 1);
        @while $i <= 9 {
            --#{$value}-color-#{$i}0: hsla(var(--#{$value}-h), var(--#{$value}-s), #{$i}0%, 1);
            $i: $i + 1;
        }
    }
}
:root {
    /* Colors
     * ====== */
    @include colorVar(primary);
    @include colorVar(secondary);
    @include colorVar(accent);
    @include colorVar(text);
    @include colorVar(background);
    --success-color: hsla(122, 39%, 49%, 1);
    --error-color: hsla(0, 100%, 45%, 1);
    --info-color: hsla(199, 98%, 48%, 1);
    /* Typography
     * ========== */
    --h4-font-size: 1.25rem;
    --h5-font-size: 1.125rem;
    --h6-font-size: 1rem;
    /* Box-shadow
     * ========== */
    --box-shadow-1: 1px 1px 4px rgba(0, 0, 0, .1);
    --box-shadow-2: 2px 2px 8px rgba(0, 0, 0, .1);
    --box-shadow-3: 4px 4px 12px rgba(0, 0, 0, .1);
    --box-shadow-4: 8px 8px 16px rgba(0, 0, 0, .1);
    --box-shadow-5: 12px 12px 24px rgba(0, 0, 0, .1);
    --box-shadow-primary-1: 1px 1px 4px hsla(var(--primary-h), var(--primary-s), var(--primary-l), .1);
    --box-shadow-primary-2: 2px 2px 8px hsla(var(--primary-h), var(--primary-s), var(--primary-l), .1);
    --box-shadow-primary-3: 4px 4px 12px hsla(var(--primary-h), var(--primary-s), var(--primary-l), .1);
    --box-shadow-primary-4: 8px 8px 16px hsla(var(--primary-h), var(--primary-s), var(--primary-l), .1);
    --box-shadow-primary-5: 12px 12px 24px hsla(var(--primary-h), var(--primary-s), var(--primary-l), .1);
} 